import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionWrapHead,
  BoxSectionHeadingswrap,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
  BoxBottomImgWrap,
} from "./style";

const BoxSection = ({ data, layout, ...props }) => {
  return (
    <BoxSectionWrapper layout={layout} {...props} id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap>
                {data &&
                  data.items.map((el, index) => (
                    <BoxSectionWrapInner key={index}>
                      <BoxSectionWrapHead>
                        <BoxSectionIconwrap>
                          <Image
                            src={el.images[0]?.src}
                            alt={el.images[0]?.alt || "Icon"}
                          />
                        </BoxSectionIconwrap>
                      </BoxSectionWrapHead>
                      <BoxSectionTextWrap>
                        <BoxSectionHeadingswrap>
                          <Heading as="h6" className="text-white">
                            {el.subject}
                          </Heading>
                          <Heading as="h4" className="text-white">
                            {el.title}
                          </Heading>
                        </BoxSectionHeadingswrap>
                        <Text>{el.description}</Text>
                        <Anchor path={el.path} key={index}>
                          {el.name}
                        </Anchor>
                      </BoxSectionTextWrap>
                    </BoxSectionWrapInner>
                  ))}
              </BoxSectionWrap>
              {/* <ButtonWrap>
                {data?.buttons?.map(({ id, content, ...rest }) => (
                  <Button key={id} m="7px" {...rest}>
                    {content}
                  </Button>
                ))}
              </ButtonWrap> */}
            </Col>
          </Row>
          {!!data.images && (
            <Row>
              <Col>
                <BoxBottomImgWrap>
                  <Image
                    src={data.images[0]?.src}
                    alt={data.images[0]?.alt || "Image"}
                  />
                </BoxBottomImgWrap>
              </Col>
            </Row>
          )}
        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

BoxSection.propTypes = {
  layout: PropTypes.oneOf([1]),
  items: PropTypes.object,
};
BoxSection.defaultProps = {
  layout: 1,
};

export default BoxSection;
