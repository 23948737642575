import styled, { device } from "@styled";

export const BoxSectionWrapper = styled.section`
  background-color: #fa4f19;
  padding: 80px 0;
    .section-title {
      h6 {
        color: #fff;
      }
      h2 {
        border-color: #fff;
        color: #000;
      }
      p {
        color: #fff;
      }
    }
    ${device.medium} {
      padding: 100px 0;
    }
    .section-title {
      p {
        color: #fff;
      }
    }
  `;

export const BoxSectionInner = styled.div``;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const BoxSectionWrapInner = styled.div`
  position: relative;
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
`;
export const BoxSectionWrapHead = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;
export const BoxSectionHeadingswrap = styled.div`
  h6 {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 15px;
  }
  h4 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 22px;
    }
  }
`;

export const BoxSectionIconwrap = styled.div`
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  min-width: 55px;
  width: 55px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  margin-top: 15px;
`;
export const BoxSectionTextWrap = styled.div`
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-family: 'Centurygothic';

    &:hover {
      color: #000;
      -webkit-transform: translateX(3px);
      -ms-transform: translateX(3px);
      transform: translateX(3px);
    }
  }
`;
export const BoxBottomImgWrap = styled.div`
  margin-bottom: -100px;
  text-align: center;
  padding-top: 70px;
  ${device.medium} {
    margin-bottom: -166px;
  }
`;
export const ButtonWrap = styled.div`
  text-align: center;
`;
